import React from "react"
import Layout from "../layouts/es"
import SEO from "../components/seo"

const FunctionalitiesPage = (props) => {
    return <Layout location={props.location}>
        <div>
            <SEO title="Funcionalidades de la firma electrónica"
                 description="Firma desde cualquier dispositivo y en minutos. Firma documentos y realiza un KYC de forma integrada. Personalización de documentos. Firma electrónica manuscrita." />

            <section className="py-5 header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="position-absolute top-right text-primary-dark mt-n12 decor2">
                                <img src={"/img/ic_functionalities_1.svg"} alt="" />
                            </div>
                            <h3 align="center" className="spacing">Funcionalidades</h3>
                        </div>
                    </div>
                </div>
            </section>

            <section className="f1 mb-6">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 text-center">
                            <br />

                            <h5><span className="badge rounded-pill bg-info mt-5 py-2 pr-3 pl-3 spacing">¿Cómo funciona?</span></h5>

                            <br /><br />

                            <h2 className="font-weight-bold spacing" data-aos="fade-up">
                                Tecalis Signature está diseñado para que se pueda <span className="text-tecalis">leer y firmar electrónicamente documentos desde cualquier dispositivo y
                            en cuestión de minutos</span>, de forma fácil e intuitiva.
                            </h2>

                            <br /><br />
                        </div>

                        <div className="col-12 col-md-10 col-lg-8" data-aos="fade-up">
                            <p className="font-size-md text-black-80 spacing">
                                Introduce las direcciones de correo electrónico de los firmantes en el orden en que quieres que
                                firmen. Los destinatarios solo tienen que hacer clic en un vínculo y firmar en un navegador con su
                                ordenador o dispositivo móvil. Nuestro sistema de notificaciones avisará en cuanto se haya
                                visualizado o firmado el documento, además de generar alertas automáticas cuando se ha establecido
                                una fecha límite. Todas las partes involucradas reciben una copia segura del documento firmado. Este
                                se almacena junto con el registro de auditoría en Internet o en el lugar que prefieras.
                            </p>

                            <p className="font-size-md text-black-80 spacing">
                                <span className="font-weight-bold text-tecalis">Tecalis Signature</span> ofrece una experiencia de usuario excepcional gracias a nuestra apuesta por
                                la
                                innovación, aumentando así la satisfacción y la experiencia de uso en los clientes.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="f2">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 text-center">
                            <br /><br /><br />

                            <div className="row">
                                <div className="col-12 col-lg-12">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-3 col-lg-3">
                                            <img src={"/img/email.svg"} alt="..." className="img-fluid" width="200" />
                                        </div>

                                        <div className="col-9 col-lg-9">
                                            <h3 align="left" className="font-weight-bold mb-2 spacing">
                                                Tan simple como enviar un correo electrónico
                                            </h3>

                                            <p align="left" className="font-size-md text-black-80 spacing">
                                                Introduce las direcciones de correo electrónico de los firmantes en el orden en que
                                                quieres que firmen. Introduce los campos para recopilar las firmas electrónicas y el
                                                resto de la información.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="row align-items-center mb-3">
                                        <div className="col-9 col-lg-9">
                                            <h3 align="left" className="font-weight-bold mb-2 spacing ml-2">
                                                Adiós a la incertidumbre
                                            </h3>

                                            <p align="left" className="font-size-md text-black-80 spacing ml-2">
                                                Recine una notificación en cuanto se haya visualizado o firmado el documento, además
                                                de alertas automáticas cuando se ha establecido una fecha límite.
                                            </p>
                                        </div>

                                        <div className="col-3 col-lg-3">
                                            <img src={"/img/notify.svg"} alt="..." className="img-fluid" width="200" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-12">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-3 col-lg-3">
                                            <img src={"/img/send.svg"} alt="..." className="img-fluid" width="200" />
                                        </div>

                                        <div className="col-9 col-lg-9">
                                            <h3 align="left" className="font-weight-bold mb-2 spacing">
                                                Firmar y enviar, igual de fácil
                                            </h3>

                                            <p align="left" className="font-size-md text-black-80 spacing">
                                                Tus destinatarios solo tienen que hacer clic en un vínculo y firmar en un navegador
                                                con su ordenador o dispositivo móvil. Sin descargas ni inicios de sesión en cuentas
                                                que relanticen el proceso.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="row align-items-center mb-3">
                                        <div className="col-9 col-lg-9">
                                            <h3 align="left" className="font-weight-bold mb-2 spacing ml-2">
                                                Archivado automático
                                            </h3>

                                            <p align="left" className="font-size-md text-black-80 spacing ml-2">
                                                Cada paso se registra. Todas las partes involucradas reciben una copia segura del
                                                documento firmado. Este se almacena junto con el registro de auditoría en Internet o
                                                en el lugar que prefieras.
                                            </p>
                                        </div>

                                        <div className="col-3 col-lg-3">
                                            <img src={"/img/server.svg"} alt="..." className="img-fluid mt-2" width="200" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br /><br /><br /><br />
                        </div>
                    </div>
                </div>

                <div className="svg-border-angled text-light">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" fill="#fff">
                        <polygon points="0,100 100,0 100,100" />
                    </svg>
                </div>
            </section>

            <section className="f1 mb-6">
                <br /><br /><br /><br />

                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-5 mb-5">
                            <img src={"/img/security.svg"} width="500" alt="..." className="img-fluid" />
                        </div>

                        <div className="col-xl-7">
                            <p className="font-size-lg text-black-80 mb-2 spacing">
                                Todos los datos que circulan a través de nuestra plataforma están encriptados y cifrados, cumpliendo
                                con los estándares más rigurosos en seguridad. Además, nuestra solución de firma digital cumple
                                tanto con la legislación europea como con la legislación de los EEUU.</p>
                            <br />
                            <h5 className="font-size-lg text-black-80 mb-2 spacing font-weight-bold">👉 Biometría</h5>
                            <p className="font-size-lg text-black-80 mb-4 spacing">
                                Nuestra solución incorpora tecnología biométrica, lo que permite capturar datos biométricos del grafo del firmante, como la velocidad o aceleración
                                con
                                la que se realiza la firma.
                            </p>
                            <h5 className="font-size-lg text-black-80 mb-2 spacing font-weight-bold">👉 Evidencias electrónicas</h5>
                            <p className="font-size-lg text-black-80 mb-4 spacing">
                                Recogemos las evidencias electrónicas generadas durante el proceso de firma en un documento probatorio, garantizando su integridad con un sellado de
                                tiempo oficial.
                            </p>
                            <h5 className="font-size-lg text-black-80 mb-2 spacing font-weight-bold">👉 Seguridad</h5>
                            <p className="font-size-lg text-black-80 mb-4 spacing">
                                Tecalis Signature incorpora firewalls de acceso y comunicaciones encriptadas de última generación, consiguiendo así un almacenamiento y cifrado de
                                datos con los más altos estándares en seguridad, limitando la lectura y firma de documentos a los usuarios exclusivamente designados.
                            </p>
                            <h5 className="font-size-lg text-black-80 mb-2 spacing font-weight-bold">👉 Protección de datos</h5>
                            <p className="font-size-lg text-black-80 mb-3 spacing">
                                En cumplimiento con la ley española y europea, durante el proceso de firma pedimos de forma expresa el consentimiento del firmante para capturar
                                sus datos, tanto personales como biométricos.
                            </p>
                        </div>
                    </div>

                    <br />
                </div>
            </section>

            <section className="py-5 links">
                <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8 decor">
                    <img src={"/img/home_signature_3.svg"} alt="" />
                </div>

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 text-center">
                            <br />

                            <h1 className="font-weight-bold spacing">
                                Personalización con marca corporativa
                            </h1>

                            <p className="text-white spacing">
                                Tecalis Signature permite a las empresas crear y personalizar los correos electrónicos que se envían
                                a los firmantes para aumentar la confianza y mejorar la experiencia de uso al recibirlos en su
                                bandeja de entrada.
                            </p>
                        </div>
                    </div>

                    <div className="links-wrapper">
                        <div className="row links-card">
                            <div className="col-xl-4">
                                <div className="card hvr-float mb-3 pers--card">
                                    <div className="card-body">
                                        <div align="center">
                                            <h5>
                                                <span className="badge rounded-pill bg-info mt-3 py-2 pr-3 pl-3">Logos personalizados</span>
                                            </h5>
                                            <img src={"/img/per-logo.svg"} className="img-fluid mt-3 mb-3" width="150" alt="..." />
                                        </div>
                                        <p className="font-size-lg text-black-80 spacing mt-5 mb-4 pl-5 pr-5">
                                            <b>Tecalis Signature</b> está diseñado para que se pueda utilizar el logo, identidad o marca corporativa en el envío de documentos, una
                                            funcionalidad perfecta para trabajar en equipo en todas las comunicaciones.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4">
                                <div className="card hvr-float mb-3 pers--card">
                                    <div className="card-body">
                                        <div align="center">
                                            <h5>
                                                <span className="badge rounded-pill bg-info mt-3 py-2 pr-3 pl-3">Emails personalizados</span>
                                            </h5>
                                            <img src={"/img/per-email.svg"} className="img-fluid mt-3 mb-3" width="150" alt="..." />
                                        </div>

                                        <p className="font-size-lg text-black-80 spacing mt-5 mb-4 pl-5 pr-5"><b>Tecalis Signature</b>
                                            permite a las empresas crear y personalizar los correos electrónicos que se envían a los
                                            firmantes para aumentar la confianza y mejorar la experiencia de uso de los clientes.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4">
                                <div className="card hvr-float mb-3 pers--card">
                                    <div className="card-body">
                                        <div align="center">
                                            <h5><span className="badge rounded-pill bg-info mt-3 py-2 pr-3 pl-3">Plantillas personalizados</span></h5>
                                            <img src={"/img/per-template.svg"} className="img-fluid mt-3 mb-3" width="150" alt="..." />
                                        </div>
                                        <p className="font-size-lg text-black-800 spacing mt-5 mb-4 pl-5 pr-5">
                                            <b>Tecalis Signature</b> permite crear plantillas personalizadas de documentos recurrentes para enviar a los usuarios, ayudando así a
                                            las
                                            compañías a ser más eficientes en todos sus procesos administrativos.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="f2">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-8 text-center" data-aos="fade-up">
                            <br /><br /><br />

                            <h1 className="display-4 font-weight-bold spacing title-per">
                                La herramienta perfecta para equipos de trabajo
                            </h1>

                            <p className="font-size-lg text-black-800 spacing">
                                <b><span className="text-tecalis">Tecalis Signature</span></b> es la herramienta perfecta para trabajar
                                en equipos de trabajo.
                            </p>

                            <br /><br />
                        </div>

                        <div align="center" className="col-12 col-xl-10 col-lg-10 text-center">
                            <img src={"/img/team.svg"} alt="..." className="img-fluid mb-6 mb-md-0" width="800" />
                        </div>

                        <div className="col-12 col-md-10 col-lg-8 check-list">
                            <br /><br /><br />

                            <ul className="spacing mb-5">
                                <li>Crea diferentes grupos invitando a la cuenta a los miembros del equipo, que también gestionarán
                                    la solicitud de firmas electrónicas o el envío de emails certificados.
                                </li>
                                <li>Asigna roles a los miembros en función de los permisos que se quieran conceder y monitoriza su
                                    actividad desde el panel de gestión.
                                </li>
                                <li>Realiza un seguimiento de las peticiones de firma y de los envíos certificados en tiempo real y
                                    controla cuánto se demoran los procesos.
                                </li>
                                <li>Detecta qué puede ser lo que ralentiza los procesos de firma y envío de emails certificados y
                                    diseña las acciones necesarias para cambiar lo que no funciona para aumentar así la eficiencia
                                    en los equipos de trabajo de tu organización.
                                </li>
                                <li>Mantén todos los documentos ordenados y almacenados de forma segura. Cualquiera del equipo con
                                    permisos podrá consultarlos en todo momento.
                                </li>
                            </ul>

                            <br /><br /><br />
                        </div>
                    </div>
                </div>

                <div className="svg-border-angled text-light">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" fill="#fff">
                        <polygon points="0,100 100,0 100,100" />
                    </svg>
                </div>
            </section>

            <section className="f1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2 text-center">
                            <br /><br />

                            <img src={"/img/multiplataform.svg"} width="700" alt="..." className="img-fluid mt-5" />
                        </div>

                        <div align="center" className="col-md-8 offset-md-2">
                            <h1 className="display-4 font-weight-bold spacing">
                                <br /><br />
                                Desde cualquier dispositivo, desde cualquier lugar
                                <br />
                            </h1>

                            <p align="left" className="font-size-lg text-black-80 mb-2 spacing">
                                Con <b>Tecalis Signature</b> tendrás en la palma de tu mano el poder de mantener el negocio en marcha. Realiza firmas desde cualquier dispositivo
                                con
                                acceso a internet sin necesidad de instalar aplicaciones.
                            </p>

                            <p align="left" className="font-size-lg text-black-80 mb-6 spacing">
                                La solicitud de firma se realiza a través de tu gestor de correo habitual, sin tener que entrar en plataformas o aplicaciones de terceros, y la
                                persona
                                que firma lo puede hacer desde su propio dispositivo móvil sin tener que instalar ninguna aplicación extra. Además, al ser una solución cloud se
                                puede
                                acceder a todos los archivos, en cualquier momento y en cualquier lugar.
                            </p>

                            <br /><br />
                        </div>
                    </div>
                </div>
            </section>
        </div>

    </Layout>
}

export default FunctionalitiesPage
